<template>
  <div>
    <b-card
      title=""
    >
      <div class="">
        登録項目はありません。
      </div>
      <b-form>
        <b-row
          v-for="(item, index) in menulist"
          :key="index"
        >
          <InputParts
            v-if="useValueDicList.indexOf(item.type) !== -1"
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value-dic="inputForm[item.key]"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type ==='checkbox' ? choice: []"
            @inputData="inputForm[item.key] = $event"
          />
          <InputParts
            v-else
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value="item.type !=='checkbox' ?inputForm[item.key]: ''"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type ==='checkbox' ? choice: []"
            @inputData="inputForm[item.key] = $event"
          />
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BForm, BCard,
  // BButton, BSpinner, BCol,
} from 'bootstrap-vue'
import InputParts from '@/components/molecules/input/InputParts.vue'
import mergeByUID from '@/firestore/data/updateDE'
import menulist from '@/components/conf/student/学習PF_受講生_基本設定_20210921.json'
import getSingleData from '@/firestore/data/get'

export default {
  components: {
    BRow,
    // BCol,
    BForm,
    // BButton,
    BCard,
    InputParts,
    // BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputForm: {},
      status: 1,
      isShow: false,
      menulist,
      useValueDicList: ['checkbox', 'selectSingle', 'radio'],
    }
  },
  computed: {
  },
  async mounted() {
    const data = {
      collection: 'user',
    }
    const response = await getSingleData(data)
    if (response.status === 'success') {
      this.inputForm = response.data
    } else {
      window.console.log('error', response.msg)
    }
  },
  methods: {
    async confirm() {
      window.console.log('🐶', this.inputForm)
      this.status = 2
      const data = {
        collection: 'user',
        d: this.inputForm,
      }
      const response = await mergeByUID(data)
      if (response.status === 'success') {
        this.success()
      } else {
        window.console.log('error', response.msg)
      }
    },
    showBtn() {
      this.$ref.sbchild.$emit('success')
    },
    success() {
      this.$swal({
        title: '登録完了！',
        text: 'データが正常に登録されました。',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.status = 1
      })
    },
  },
}
</script>
